import api from '../api';

export const getAllRoles = async () => {
  try {
    const response = await api.get('/admin/role');

    return response.data.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerGetCompany = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
