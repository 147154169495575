import {
  UilBuilding,
  UilHome,
  UilUsersAlt,
  UilFileCheck,
  UilFileAlt,
  UilBookOpen,
  UilGraphBar,
  UilSetting,
  UilApps,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}`}
      >
        {t('dashboard')}
      </NavLink>,
      't-menu-dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}`}>
          <UilHome className={`w-6 h-6 text-[#52525B] ${pathname === `${path}` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`font-Jost font-normal text-base ${pathname === `${path}/companies` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/companies`}
      >
        {t('companies')}
      </NavLink>,
      't-menu-companies',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/companies`}>
          <UilBuilding className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/companies` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/students` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/students`}
      >
        {t('students')}
      </NavLink>,
      't-menu-students',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/students`}>
          <UilUsersAlt className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/students` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/families` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/families`}
      >
        {t('Career Paths')}
      </NavLink>,
      't-menu-family-roles',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/families`}>
          <UilApps className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/families` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/roles` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/roles`}
      >
        {t('roles')}
      </NavLink>,
      't-menu-roles',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/roles`}>
          <UilFileCheck className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/roles` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/achievements` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/achievements`}
      >
        {t('achievements')}
      </NavLink>,
      't-menu-achievements',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/achievements`}>
          <UilFileAlt
            className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/achievements` ? 'text-primary' : ''}`}
          />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/schools` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/schools`}
      >
        {t('schools')}
      </NavLink>,
      't-menu-schools',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/schools`}>
          <UilBookOpen className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/schools` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/reports` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/reports`}
      >
        {t('reports')}
      </NavLink>,
      't-menu-reports',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/reports`}>
          <UilGraphBar className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/reports` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink
        className={`"font-Jost font-normal text-base ${pathname === `${path}/manager` ? 'text-primary' : ''}`}
        onClick={toggleCollapsed}
        to={`${path}/manager`}
      >
        {t('Content Management')}
      </NavLink>,
      't-menu-manager',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/manager`}>
          <UilSetting className={`w-6 h-6 text-[#52525B] ${pathname === `${path}/manager` ? 'text-primary' : ''}`} />
        </NavLink>
      ),
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
