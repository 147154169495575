import React, { useCallback, useEffect, useMemo, useState, createRef } from 'react';
import { Breadcrumb, Button, Spin, notification } from 'antd';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { UilText, UilEdit, UilClipboardNotes, UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons';
import { LoadingOutlined } from '@ant-design/icons';
import AccordionQuestion from './AccordionQuestion';
import getQuiz from './getQuiz';
import { alertModal } from '../../../components/modals/antd-modals';
import '../modal.css';
import { getQuizById } from '../../../services/requests/getQuizById';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { updateQuiz } from '../../../services/requests/updateQuiz';
import { createQuiz } from '../../../services/requests/createQuiz';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function AddQuiz() {
  const [isSaving, setIsSaving] = useState(false);
  const { achievementId } = useParams();
  const [quiz, setQuiz] = useState([]);
  const history = useNavigate();
  const isEdit = useQuery().get('isEdit');
  const [isLoading, setIsLoading] = useState(true);
  const hasToCreate = !quiz.length;

  const parsedQuestions = useMemo(() => {
    if (isLoading) {
      return [];
    }

    if (!quiz.length) {
      return getQuiz(achievementId);
    }

    return quiz?.map((question) => ({
      ...question,
      completed: true,
      answers: question.answers?.map((answer) => ({
        ...answer,
        fixed: true,
      })),
    }));
  }, [quiz, isLoading, achievementId]);

  const refsById = useMemo(() => {
    const refsAccording = {};

    Array.from({ length: 10 }, (_, index) => index + 1).forEach((item) => {
      refsAccording[item] = createRef();
    });

    return refsAccording;
  }, []);

  const fetchQuizById = useCallback(async () => {
    setIsLoading(true);

    const response = await getQuizById(achievementId);

    setIsLoading(false);

    setQuiz(response);
  }, [achievementId]);

  useEffect(() => {
    async function fetchApiAchievement() {
      await fetchQuizById();
    }

    fetchApiAchievement();
  }, []);

  const save = async () => {
    const questions = Object.values(refsById).map((ref) => ref.current?.getQuestion());

    if (questions.some((question) => !question.completed)) {
      notification.error({
        message: 'Edit Achievements',
        description: 'Some achievements are incompleted.',
      });

      return;
    }

    setIsSaving(true);

    try {
      if (hasToCreate) {
        await createQuiz(questions);
      } else {
        await updateQuiz({
          achievementId,
          questions: Object.values(refsById).map((ref) => ref.current?.getQuestion()),
        });
      }
      notification.success({
        message: 'Edit Achievements',
        description: 'Quiz was successfully updated for the achievement.',
      });
    } catch (error) {
      notification.error({
        message: 'Edit Achievements',
        description: 'Something went wrong. Please try again.',
      });

      setIsSaving(false);
    } finally {
      history(`/admin/achievements`);
    }
  };

  const handleSubmit = async () => {
    await save();
  };

  const handleDiscard = () => {
    alertModal.confirm({
      content: 'Are you sure you want to exit without saving? Your current progress will be lost.',
      title: 'Discard changes?',
      cancelText: 'No, go back to editing',
      okText: 'Yes, exit without saving',
      className: 'footerStyle',
      onOk: () => {
        history('/admin/achievements');
      },
      onCancel() {},
    });
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/achievements" className="text-table text-sm font-Rubik font-normal">
            Achievements
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Edit Achievement</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[160px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <div className="flex flex-col bg-white rounded-md">
            {!isEdit ? (
              <>
                <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
                  <UilText width={20} height={20} className="mr-1" /> General
                </p>
                <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
                  <UilEdit width={20} height={20} className="mr-1" /> Details
                </p>
                <p className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm">
                  <UilClipboardNotes width={20} height={20} className="mr-1" /> Quiz
                </p>
              </>
            ) : (
              <>
                <NavLink
                  to={`/admin/achievements/edit/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
                >
                  <UilText width={20} height={20} className="mr-1" /> General
                </NavLink>
                <NavLink
                  to={`/admin/achievements/edit/details/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
                >
                  <UilEdit width={20} height={20} className="mr-1" /> Details
                </NavLink>
                <NavLink
                  to={`/admin/achievements/edit/quiz/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm"
                >
                  <UilClipboardNotes width={20} height={20} className="mr-1" /> Quiz
                </NavLink>
              </>
            )}
          </div>
        </div>

        <div className="flex overflow-y-auto flex-col gap-4 w-full">
          {isLoading && (
            <SpinerWraperStyle className="m-[25px]">
              <Spin indicator={antIcon} />
            </SpinerWraperStyle>
          )}
          {!isLoading && (
            <>
              {parsedQuestions.map((question, idx) => (
                <AccordionQuestion ref={refsById[idx + 1]} question={question} idx={idx} key={question.id} />
              ))}
            </>
          )}
          <div className="flex items-center justify-center gap-3">
            <Button
              onClick={handleDiscard}
              className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
            >
              <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
            </Button>

            <Button
              onClick={handleSubmit}
              className="min-w-[100px] bg-primary border-primary text-black max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
            >
              <UilCheckCircle width={16} height={16} className="mr-1" />
              {isSaving ? 'Loading...' : 'Save & Exit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddQuiz;
