import api from '../api';

export const getCompany = async (props) => {
  try {
    const response = await api.get('/admin/company', {
      params: {
        page: props?.page || 1,
        limit: props?.limit || 10,
      },
    });

    return {
      currentPage: response.data.currentPage,
      totalItems: response.data.totalItems,
      totalPages: response.data.totalPages,
      companies: response.data.data,
    };
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerGetCompany = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
