import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Upload,
  message,
  Form,
  Input,
  Button,
  notification,
  Avatar,
  Radio,
  Spin,
  Select,
  Switch,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  UilText,
  UilEdit,
  UilCheckCircle,
  UilTimesCircle,
  UilArrowToRight,
  UilClipboardNotes,
  UilPaperclip,
} from '@iconscout/react-unicons';
import FontAwesome from 'react-fontawesome';
import { LoadingOutlined, PlusOutlined, UserOutlined, CameraOutlined } from '@ant-design/icons';
import { AvatarWraperStyle, SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import MailComposer from '../../email/overview/MailComposer';
import { getAchievementById } from '../../../services/requests/getAchievementById';
import { getAchievementCategories } from '../../../services/requests/getAchievementCategories';
import { isErrorHandlerUpdateAchievement, updateAchievement } from '../../../services/requests/updateAchievement';
import { getSchoolWithGraduation } from '../../../services/requests/getGraduation';
import { alertModal } from '../../../components/modals/antd-modals';
import '../modal.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

function EditAchievement() {
  const [state, setState] = useState({
    loading: false,
    isSaving: false,
    selectedItems: [],
    categoryId: '',
  });
  const { achievementId } = useParams();
  const [image, setImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [typeAchievement, setTypeAchievement] = useState('task');
  const [requiredValidation, setRequiredValidation] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(false);

  // Fields to validations
  const [title, setTitle] = useState('');
  const [overview, setOverview] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [achievementImage, setAchievementImage] = useState('');
  const [graduationIds, setGraduationsIds] = useState([]);
  const [graduation, setGraduation] = useState([]);
  const [achievementCategories, setAchievementCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');

  const history = useNavigate();

  const { imageUrl } = state;

  const [form] = Form.useForm();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/SVG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const fetchAchievementById = useCallback(async () => {
    setIsLoading(true);

    const response = await getAchievementById(achievementId);
    const responseGraduation = await getSchoolWithGraduation();
    const responseAchievementCategories = await getAchievementCategories();

    setAchievementCategories(responseAchievementCategories);

    setState({ ...state, categoryId: response.categoryId });

    setIsLoading(false);

    setGraduation(responseGraduation);
    setGraduationsIds(response.graduationClasses);
    setCategoryId(response.categoryId);
    setTitle(response.title);
    setOverview(response.overview);
    setShowLandingPage(response.showLandingPage);
    setTypeAchievement(response.typeAchievement);
    setRequiredValidation(response.requiredValidation);
    setAchievementImage(response.achievementImage);
    setFileName(response.name);
  }, [achievementId]);

  useEffect(() => {
    async function fetchApiAchievement() {
      await fetchAchievementById();
    }

    fetchApiAchievement();
  }, []);

  const handleChange = (selectedItems) => {
    setState({ ...state, selectedItems });
  };

  const handleChangeCategory = (selectedItem) => {
    setState({ ...state, categoryId: selectedItem });
  };

  const onHandleChange = (info) => {
    if (info.file.status === 'uploading') {
      setState({ ...state, loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrlPath) => {
        setState({
          imageUrl: imageUrlPath,
          loading: false,
        });
        setImage(imageUrlPath.split(',')[1]);
        setFileName(info.file.name);
        setMimeType(info.file.type);
      });
    }
  };

  const uploadButton = (
    <div>
      {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const save = async (values, isNextButton) => {
    setState({
      ...state,
      isSaving: true,
    });

    let payload = {
      id: achievementId,
      graduationClassIds: state.selectedItems,
      categoryId: state.categoryId,
      ...values,
    };

    if (state.imageUrl) {
      payload = {
        ...payload,
        fileName,
        content: image,
        mimeType,
      };
    } else if (!achievementImage) {
      payload = {
        ...payload,
        fileName: '',
        content: '',
        mimeType: '',
      };
    }

    payload.requiredValidation = requiredValidation;
    payload.showLandingPage = showLandingPage;
    const response = await updateAchievement(payload);

    if (isErrorHandlerUpdateAchievement(response)) {
      notification.error({
        message: 'Update achievement',
        description: response.message,
      });

      setState({
        ...state,
        isSaving: false,
      });

      return;
    }

    notification.success({
      message: 'Update achievement',
      description: 'Achievement were successfully updated.',
    });

    if (isNextButton) {
      history(`/admin/achievements/edit/details/${response.data.id}`);
    } else {
      history(`/admin/achievements`);
    }
  };

  const handleSubmit = async (values) => {
    await save(values, false);
  };

  const handleSubmitNext = async () => {
    await save(
      {
        title,
        overview,
        typeAchievement,
        requiredValidation,
      },
      true,
    );
  };

  const handleDiscard = () => {
    alertModal.confirm({
      content: 'Are you sure you want to exit without saving? Your current progress will be lost.',
      title: 'Discard changes?',
      cancelText: 'No, go back to editing',
      okText: 'Yes, exit without saving',
      className: 'footerStyle',
      onOk: () => {
        history('/admin/achievements');
      },
      onCancel() {},
    });
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/achievements" className="text-table text-sm font-Rubik font-normal">
            Achievements
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Edit Achievement</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[160px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <div className="flex flex-col bg-white rounded-md">
            <NavLink className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm">
              <UilText width={20} height={20} className="mr-1" /> General
            </NavLink>
            {typeAchievement === 'course' && (
              <>
                <NavLink
                  to={`/admin/achievements/edit/details/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
                >
                  <UilEdit width={20} height={20} className="mr-1" /> Details
                </NavLink>
                <NavLink
                  to={`/admin/achievements/edit/quiz/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
                >
                  <UilClipboardNotes width={20} height={20} className="mr-1" /> Quiz
                </NavLink>
              </>
            )}
          </div>
        </div>

        <div className="flex overflow-y-auto flex-col gap-4 w-full">
          {isLoading && (
            <SpinerWraperStyle className="m-[25px]">
              <Spin indicator={antIcon} />
            </SpinerWraperStyle>
          )}
          {!isLoading && (
            <>
              <div className="flex overflow-y-auto flex-col gap-4 w-full">
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  className="avatar-uploader ssm:text-center sm:text-center md:text-center"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={({ file, onSuccess }) => {
                    onSuccess(file);
                  }}
                  onChange={onHandleChange}
                >
                  <AvatarWraperStyle
                    className={`flex items-center mb-[10px] rounded-full ${imageUrl ? '' : 'border-2 border-white'}`}
                  >
                    {imageUrl || achievementImage ? (
                      <>
                        <img
                          src={imageUrl || achievementImage}
                          alt="avatar"
                          className="object-contain w-[100px] h-[100px] bg-[#f4f5f7] rounded-[50%] border-white"
                        />
                        <Avatar
                          className="bg-[#EEB711] text-black absolute ml-16 mt-16 border-2 border-white flex justify-center"
                          icon={<CameraOutlined />}
                        >
                          {uploadButton}
                        </Avatar>
                      </>
                    ) : (
                      <>
                        <Avatar size={100} icon={<UserOutlined />} />
                        <Avatar
                          className="bg-[#EEB711] text-black absolute ml-16 mt-16 border-2 border-white flex justify-center"
                          icon={<CameraOutlined />}
                        >
                          {uploadButton}
                        </Avatar>
                      </>
                    )}
                  </AvatarWraperStyle>
                </Upload>
                {fileName && achievementImage && (
                  <div className="flex items-center p-2 gap-2 bg-yellow-100 rounded-xl text-primary line-clamp-1">
                    <UilPaperclip className="w-[16px] h-[16px]" />

                    <p className="line-clamp-1 m-0 max-w-[84px]">{fileName}</p>

                    <FontAwesome
                      name="close"
                      className="text-center text-sm text-[#666] bg-white rounded-[50%] items-center justify-center w-[20px] cursor-pointer"
                      onClick={() => {
                        setFileName('');
                        setImage('');
                        setState({
                          ...state,
                          imageUrl: '',
                        });
                        setAchievementImage('');
                      }}
                    />
                  </div>
                )}
              </div>

              <Form
                name="achievement"
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                  title,
                  typeAchievement,
                  overview,
                  graduationClassIds: graduationIds,
                  requiredValidation,
                  showLandingPage,
                }}
              >
                <Form.Item
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  rules={[{ message: 'Please input the achievement title!', required: true }]}
                  label="Achievement Title"
                  className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
                >
                  <Input placeholder="Title" />
                </Form.Item>

                <Form.Item
                  name="achievementCategories"
                  rules={[{ message: 'Please select one category!', required: true }]}
                  label="Add Category"
                  initialValue={categoryId}
                >
                  <Select
                    className="[&>div]:border-normal dark:[&>div]:border-white10 [&>div]:rounded-6 [&>.ant-select-arrow]:text-theme-gray dark:[&>.ant-select-arrow]:text-white60 [&>div>div>div>span]:bg-transparent [&>div>div>div>span]:h-[26px] [&>div>div>div>span]:items-center"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={state.selectedItem}
                    onChange={handleChangeCategory}
                  >
                    {achievementCategories.map((data) => (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="typeAchievement"
                  onChange={(e) => setTypeAchievement(e.target.value)}
                  rules={[{ message: 'Please select the type of the achievement!', required: true }]}
                  label="Type of Achievement"
                  initialValue="task"
                >
                  <Radio.Group defaultValue="task" size="large" disabled>
                    <Radio value="task">Task</Radio>
                    <Radio value="course">Course</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="requiredValidation">
                  <Switch checked={requiredValidation} onChange={(e) => setRequiredValidation(e)} />
                  <span className="ml-2">Require validation</span>
                </Form.Item>

                {typeAchievement === 'task' && (
                  <Form.Item name="showLandingPage">
                    <Switch checked={showLandingPage} onChange={(e) => setShowLandingPage(e)} />
                    <span className="ml-2">Show landing page</span>
                  </Form.Item>
                )}

                <Form.Item
                  name="graduationClassIds"
                  label="Graduation Classes"
                  rules={[{ message: 'Please select at least one graduation class!', required: true }]}
                >
                  <Select
                    className="[&>div]:border-normal dark:[&>div]:border-white10 [&>div]:rounded-6 [&>.ant-select-arrow]:text-theme-gray dark:[&>.ant-select-arrow]:text-white60 [&>div>div>div>span]:bg-transparent [&>div>div>div>span]:h-[26px] [&>div>div>div>span]:items-center"
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={state.selectedItems}
                    onChange={handleChange}
                  >
                    {graduation.map((data) => (
                      <Option key={data.id} value={data.id}>
                        {data.year}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="overview"
                  onChange={(e) => setOverview(e.target.value)}
                  rules={[{ message: 'Please input the description!', required: true }]}
                  label="Achievement Description / Skill Overview"
                  className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
                >
                  <MailComposer text onChange={(e) => setOverview(e)} initialValue={overview} />
                </Form.Item>
                <div className="flex items-center justify-center gap-3">
                  <Form.Item>
                    <NavLink
                      onClick={handleDiscard}
                      className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
                    >
                      <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
                    </NavLink>
                  </Form.Item>
                  {typeAchievement === 'course' && (
                    <Form.Item>
                      <Button
                        onClick={handleSubmitNext}
                        type="primary"
                        disabled={!title || !overview || state.isSaving}
                        className="bg-white text-[#A7800C] max-w-[150px] border-gray-200 border-1 text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
                      >
                        <UilArrowToRight width={16} height={16} className="mr-1" />
                        {state.isSaving ? 'Loading...' : 'Next'}
                      </Button>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={!title || !overview || state.isSaving}
                      className={`min-w-[100px] ${
                        !title || !overview ? 'text-[#9CA3AF] bg-[#F3F4F6]' : 'bg-primary border-primary text-black'
                      } border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
                    >
                      <UilCheckCircle width={16} height={16} className="mr-1" />
                      {state.isSaving
                        ? 'Loading...'
                        : typeAchievement === 'task'
                        ? 'Save & Update Achievement'
                        : 'Save & Exit'}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditAchievement;
