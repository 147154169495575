import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Spin, notification } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  UilPostcard,
  UilFileCheck,
  UilRocket,
  UilUsdCircle,
  UilTimesCircle,
  UilCheckCircle,
  UilArrowToRight,
  UilDollarSign,
} from '@iconscout/react-unicons';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { getAllRoles } from '../../../services/requests/getAllRoles';
import { Checkbox } from '../../../components/checkbox/checkbox';
import { createRoleCompany, isErrorHandlerCreateRoleCompany } from '../../../services/requests/createRoleCompany';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function AddRoles() {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [rolesChecked, setRolesChecked] = useState([]);
  const history = useNavigate();
  const { companyId } = useParams();

  useEffect(() => {
    async function fetchRoles() {
      const response = await getAllRoles();

      setRoles(response);

      setIsLoading(false);
    }

    fetchRoles();
  }, [companyId]);

  const save = async (isNextButton) => {
    setIsSaving(true);

    if (rolesChecked.length === 0) {
      notification.error({
        message: 'Create Roles',
        description: 'Select at least one role to continue',
      });

      setIsSaving(false);

      return;
    }

    const response = await createRoleCompany({
      companyId,
      rolesIds: rolesChecked,
    });

    if (isErrorHandlerCreateRoleCompany(response)) {
      notification.error({
        message: 'Create Roles',
        description: response.message,
      });

      setIsSaving(false);

      return;
    }

    notification.success({
      message: 'Create Roles',
      description: 'Roles was successfully created for the company.',
    });

    if (isNextButton) {
      history(`/admin/companies/new/payout/${companyId}`);
    } else {
      history(`/admin/companies`);
    }
  };

  const handleSubmit = async () => {
    await save(false);
  };

  const handleSubmitNext = async () => {
    await save(true);
  };

  const onChange = (roleId, checked) => {
    if (checked) {
      setRolesChecked([...rolesChecked, roleId]);
    } else {
      setRolesChecked([...rolesChecked.filter((roleChecked) => roleChecked !== roleId)]);
    }
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/companies" className="text-table text-sm font-Rubik font-normal">
            Companies
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Add Company</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[268px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilPostcard width={20} height={20} className="mr-1" /> Company Profile
          </p>
          <p className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm">
            <UilFileCheck width={20} height={20} className="mr-1" /> Roles
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilDollarSign width={20} height={20} className="mr-1" /> Max Payout
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilRocket width={20} height={20} className="mr-1" /> Achievements
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilUsdCircle width={20} height={20} className="mr-1" /> Pricing
          </p>
        </div>

        {isLoading && (
          <SpinerWraperStyle className="m-[25px]">
            <Spin indicator={antIcon} />
          </SpinerWraperStyle>
        )}

        {!isLoading && (
          <div className="flex overflow-y-auto flex-col gap-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3 4xl:grid-cols-4 5xl:grid-cols-5 gap-4">
              {roles.map((role) => (
                <div
                  key={role.id}
                  className="bg-white m-0 p-0 text-theme-gray dark:text-white60 text-[15px] mb-[25px] rounded-10 relative min-w-[230px] h-[220px]"
                >
                  <div className="p-4 flex items-center text-dark font-medium text-[17px] border-regular border-b">
                    <h1 className="mb-0 inline-block overflow-hidden whitespace-nowrap text-ellipsis text-[18px] font-semibold">
                      {role.title}
                    </h1>
                  </div>
                  <div className="p-2">
                    <Checkbox
                      checked={rolesChecked.some((roleChecked) => roleChecked === role.id)}
                      onChange={(checked) => onChange(role.id, checked)}
                    >
                      <p className="line-clamp-5">{role.previewDescription}</p>
                    </Checkbox>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center gap-3">
              <NavLink
                to="/admin/companies"
                className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
              </NavLink>

              <Button
                onClick={handleSubmitNext}
                disabled={!rolesChecked.length || isSaving}
                className="bg-white text-[#A7800C] max-w-[150px] border-gray-200 border-1 text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilArrowToRight width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Next'}
              </Button>

              <Button
                onClick={handleSubmit}
                disabled={!rolesChecked.length || isSaving}
                className={`min-w-[100px] ${
                  !rolesChecked.length ? 'text-[#9CA3AF] bg-[#F3F4F6]' : 'bg-primary border-primary text-black'
                } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
              >
                <UilCheckCircle width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Save & Exit'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddRoles;
