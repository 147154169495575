import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button, Pagination, Spin, notification } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  UilBuilding,
  UilRocket,
  UilUsdCircle,
  UilCheckCircle,
  UilTimesCircle,
  UilText,
  UilArrowToRight,
} from '@iconscout/react-unicons';
import { LoadingOutlined } from '@ant-design/icons';
import { getCompany } from '../../../services/requests/getCompany';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { Checkbox } from '../../../components/checkbox/checkbox';
import { createCompanyRole, isErrorHandlerCreateCompanyRole } from '../../../services/requests/createCompanyRole';
import { getCompaniesByRoleId } from '../../../services/requests/getCompaniesByRoleId';
import { alertModal } from '../../../components/modals/antd-modals';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function EditCompanyRole() {
  const [companies, setCompanies] = useState([]);
  const [companiesApiIds, setCompaniesApiIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesChecked, setCompaniesChecked] = useState([]);
  const { roleId } = useParams();
  const history = useNavigate();

  const checkedCompany = (cmp) => {
    const company = companiesChecked.find((companyC) => companyC.companyId === cmp.id);

    if (company) {
      return company.checked;
    }

    return cmp.checked;
  };

  const fetchCompany = useCallback(
    async (page = 1) => {
      setIsLoading(true);

      const [response, companiesApi] = await Promise.all([
        getCompany({ page, limit: 30 }),
        getCompaniesByRoleId(roleId),
      ]);

      setIsLoading(false);

      setCompanies(
        response.companies.map((company) => ({
          ...company,
          checked:
            companiesApi.some(
              (cmp) =>
                cmp.id === company.id && companiesChecked.find((x) => x.companyId === cmp.id && x.checked === true),
            ) || companiesApi.some((cmp) => cmp.id === company.id),
        })),
      );
      setCurrentPage(page);
      setTotalItems(response.totalItems);
      setCompaniesApiIds(companiesApi.map((company) => ({ companyId: company.id })));
    },
    [currentPage, companiesChecked],
  );

  useEffect(() => {
    async function fetchApiCompany() {
      await fetchCompany(1);
    }

    fetchApiCompany();
  }, []);

  const save = async (isNextButton) => {
    setIsSaving(true);

    if (companiesChecked.length === 0) {
      notification.error({
        message: 'Update Roles',
        description: 'Select at least one company to continue',
      });

      setIsSaving(false);

      return;
    }

    const payload = [];

    const companyIdSet = new Set(companiesApiIds.map((item) => item.companyId));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of companiesChecked) {
      if (!companyIdSet.has(item.companyId)) {
        payload.push(item.companyId);
      }
    }

    payload.push(
      ...companiesApiIds
        .filter(
          (cmp) =>
            !companiesChecked.find(
              (cmpChecked) => cmpChecked.companyId === cmp.companyId && cmpChecked.checked === false,
            ),
        )
        .map((cmp) => cmp.companyId),
    );

    if (payload.length === 0) {
      notification.error({
        message: 'Update Roles',
        description: 'Select at least one company to continue',
      });

      setIsSaving(false);

      return;
    }

    const response = await createCompanyRole({
      roleId,
      companiesIds: payload,
    });

    if (isErrorHandlerCreateCompanyRole(response)) {
      notification.error({
        message: 'Update Roles',
        description: response.message,
      });

      setIsSaving(false);

      return;
    }

    notification.success({
      message: 'Update Roles',
      description: 'Companies was successfully updated for the role.',
    });

    if (isNextButton) {
      history(`/admin/roles/edit/achievements/${roleId}`);
    } else {
      history(`/admin/roles`);
    }
  };

  const handleSubmitNext = async () => {
    await save(true);
  };

  const handleSubmit = async () => {
    await save(false);
  };

  const onChange = (companyId, checked) => {
    const companyIndex = companiesChecked.findIndex((cmp) => cmp.companyId === companyId);

    if (companyIndex !== -1) {
      companiesChecked[companyIndex].checked = !companiesChecked[companyIndex].checked;

      setCompaniesChecked([...companiesChecked]);
    } else {
      setCompaniesChecked([...companiesChecked, { companyId, checked }]);
    }
  };

  const handleDiscard = () => {
    alertModal.confirm({
      content: 'Are you sure you want to exit without saving? Your current progress will be lost.',
      title: 'Discard changes?',
      cancelText: 'No, go back to editing',
      okText: 'Yes, exit without saving',
      className: 'footerStyle',
      onOk: () => {
        history('/admin/roles');
      },
      onCancel() {},
    });
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/roles" className="text-table text-sm font-Rubik font-normal">
            Roles
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Edit Role</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[208px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <NavLink
            to={`/admin/roles/edit/${roleId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilText width={20} height={20} className="mr-1" /> Role
          </NavLink>
          <NavLink className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm cursor-pointer">
            <UilBuilding width={20} height={20} className="mr-1" /> Companies
          </NavLink>
          <NavLink
            to={`/admin/roles/edit/achievements/${roleId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilRocket width={20} height={20} className="mr-1" /> Achievements
          </NavLink>
          <NavLink
            to={`/admin/roles/edit/achievements/price/${roleId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilUsdCircle width={20} height={20} className="mr-1" /> Pricing
          </NavLink>
        </div>

        {isLoading && (
          <SpinerWraperStyle className="m-[25px]">
            <Spin indicator={antIcon} />
          </SpinerWraperStyle>
        )}

        {!isLoading && (
          <div className="flex overflow-y-auto flex-col gap-4 w-full">
            <div className="flex w-full justify-center">
              <div className="flex flex-1 flex-wrap rounded-sm">
                {companies.map((company) => (
                  <div
                    key={company.id}
                    className="flex items-center p-2 flex-1 min-w-[33%] max-w-[33%] bg-white border"
                  >
                    <Checkbox checked={checkedCompany(company)} onChange={(checked) => onChange(company.id, checked)}>
                      <p className="line-clamp-1 m-0">{company.name}</p>
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
            <Pagination
              className="mt-4"
              defaultCurrent={1}
              current={currentPage}
              total={totalItems}
              pageSize={30}
              onChange={fetchCompany}
            />
            <div className="flex items-center justify-center gap-3">
              <Button
                onClick={handleDiscard}
                className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
              </Button>

              <Button
                onClick={handleSubmitNext}
                type="primary"
                disabled={!companiesChecked.length || isSaving}
                className="bg-white text-[#A7800C] max-w-[150px] border-gray-200 border-1 text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilArrowToRight width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Next'}
              </Button>

              <Button
                onClick={handleSubmit}
                disabled={!companiesChecked.length || isSaving}
                className={`min-w-[100px] ${
                  !companiesChecked.length ? 'text-[#9CA3AF] bg-[#F3F4F6]' : 'bg-primary border-primary text-black'
                } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
              >
                <UilCheckCircle width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Save & Exit'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditCompanyRole;
