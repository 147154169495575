import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UilPlusCircle } from '@iconscout/react-unicons';
import DataTable from '../../components/table/DataTable';
import { SpinerWraperStyle } from '../ui-elements/ui-elements-styled';
import { getSchool } from '../../services/requests/getSchool';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function Schools() {
  const [schools, setSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchSchool = useCallback(
    async (page = 1) => {
      setIsLoading(true);

      const response = await getSchool({ page });

      setIsLoading(false);

      setSchools(response.schools);
      setCurrentPage(page);
      setTotalItems(response.totalItems);
    },
    [currentPage],
  );

  useEffect(() => {
    async function fetchApiSchool() {
      await fetchSchool();
    }

    fetchApiSchool();
  }, []);

  const tableDataSource = [];

  if (schools?.length > 0) {
    schools.map((item, index) => {
      const { name, state, city } = item;
      return tableDataSource.push({
        key: item.id,
        name: (
          <span className="text-xs" data-test-id={`school-element-${index}`}>
            {name}
          </span>
        ),
        location: (
          <span className="text-xs">
            {city ? `${city}, ` : ''} {state}
          </span>
        ),
      });
    });
  }

  const dataTableColumn = [
    {
      title: "School's Name",
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
  ];

  return (
    <div className="flex flex-col p-5">
      <div className="flex items-center gap-3 mb-4">
        <NavLink
          to="add"
          className="bg-primary ssm:w-full w-[160px] border-solid border-1 border-primary text-black text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
        >
          <UilPlusCircle width={16} height={16} className="mr-1" /> Add School
        </NavLink>
      </div>

      {isLoading && (
        <SpinerWraperStyle className="m-[25px]">
          <Spin indicator={antIcon} />
        </SpinerWraperStyle>
      )}

      {!isLoading && (
        <div className="bg-white p-3 text-table font-normal font-Inter">
          <DataTable tableData={tableDataSource} columns={dataTableColumn} />

          <div className="flex w-full justify-center">
            <Pagination
              className="mt-4"
              defaultCurrent={1}
              current={currentPage}
              total={totalItems}
              pageSize={10}
              onChange={fetchSchool}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Schools;
