import api from '../api';

export const getSchoolWithGraduation = async () => {
  const response = await api.get('/graduation-class', {
    // params: {
    //   graduation: true,
    // },
  });

  return response.data.data;
};
