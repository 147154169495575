import api from '../api';

export const createSchool = async (props) => {
  try {
    const response = await api.post('/admin/school', props);

    return response.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerSchool = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
