import { v4 as uuidv4 } from 'uuid';

export default function (achievementId) {
  const achievementArray = [
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
    {
      id: uuidv4(),
      achievementId,
      question: '',
      completed: false,
      answers: [
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
        { optionId: uuidv4(), option: '', correctAnswer: false, fixed: true },
      ],
    },
  ];

  return achievementArray;
}
