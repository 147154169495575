import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Pagination, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UilPlusCircle, UilEdit, UilTrash } from '@iconscout/react-unicons';
import { SpinerWraperStyle } from '../ui-elements/ui-elements-styled';
import { Button } from '../../components/buttons/buttons';
import { alertModal } from '../../components/modals/antd-modals';
import { getAchievements } from '../../services/requests/getAchievements';
import { deleteAchievementById, isErrorHandlerDeleteAchievement } from '../../services/requests/deleteAchievementById';
import { addStyleTailwind } from '../../utility/formatHTML';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function Achievements() {
  const [achievements, setAchievements] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAchievements = useCallback(
    async (page = 1) => {
      setIsLoading(true);

      const response = await getAchievements(page);

      setIsLoading(false);

      setAchievements(response.achievements);
      setCurrentPage(page);
      setTotalItems(response.totalItems);
    },
    [currentPage],
  );

  useEffect(() => {
    async function fetchEffectAchievements() {
      await fetchAchievements();
    }

    fetchEffectAchievements();
  }, []);

  const handleDeleteAchievement = (achievementId) => {
    alertModal.confirm({
      title: 'Do you want to delete this achievement?',
      content: 'When clicked the OK button, this achievement will be deleted',
      onOk: async () => {
        const response = await deleteAchievementById(achievementId);

        if (isErrorHandlerDeleteAchievement(response)) {
          notification.error({
            message: 'Delete achievement',
            description: response.message,
          });

          return;
        }

        notification.success({
          message: 'Delete achievement',
          description: 'Achievement as successfully deleted',
        });

        await fetchAchievements();
      },
      onCancel() {},
    });
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <div className="flex items-center gap-3 mb-4">
        <NavLink
          to="add"
          className="bg-primary ssm:w-full sm::w-full md:w-full border-solid border-1 border-primary text-black text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
        >
          <UilPlusCircle width={16} height={16} className="mr-1" /> Create Achievement
        </NavLink>
      </div>

      {isLoading && (
        <SpinerWraperStyle className="m-[25px]">
          <Spin indicator={antIcon} />
        </SpinerWraperStyle>
      )}

      {!isLoading && (
        <div className="text-table font-normal font-Inter grid ssm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 5xl:grid-cols-6 gap-4">
          {achievements?.map((achievement, index) => (
            <div
              key={achievement.id}
              data-test-id={`achievement-element-${index}`}
              className="bg-white p-4 text-theme-gray text-[15px] border rounded-10 h-[220px] flex flex-col justify-between"
            >
              <div className="flex items-center justify-center p-2 rounded-[32px] bg-yellow-50 max-w-[53px] h-[32px]">
                <span className="text-sm text-primary">
                  {achievement.typeAchievement === 'course' ? 'Quiz' : 'Task'}
                </span>
              </div>
              <div className="flex gap-2">
                <img
                  src={achievement.achievementImage || '/achievement.png'}
                  alt="achievement"
                  className="max-h-[100px] max-w-[114px] object-contain"
                />
                <div className="flex flex-col gap-2 line-clamp-1">
                  <h1 className="mb-0 inline-block overflow-hidden whitespace-nowrap text-ellipsis text-[14px] font-semibold">
                    {achievement.title}
                  </h1>
                  <span
                    className="line-clamp-4 m-0 text-xs [&>ul]:ml-4 [&>ol]:ml-4"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: addStyleTailwind(achievement.overview) }}
                  />
                </div>
              </div>

              <div className="flex w-full justify-start gap-2">
                <NavLink
                  to={`edit/${achievement.id}`}
                  className="flex items-center justify-center w-8 h-8 border border-primary rounded-md"
                >
                  <UilEdit className="w-4 text-primary" />
                </NavLink>
                <Button
                  className="border-solid border-1 border-primary text-primary dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-[4px] h-[32px]"
                  size="small"
                  onClick={() => handleDeleteAchievement(achievement.id)}
                >
                  <UilTrash className="w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Pagination
        className="mt-4"
        defaultCurrent={1}
        current={currentPage}
        total={totalItems}
        pageSize={12}
        onChange={fetchAchievements}
        showSizeChanger={false}
      />
    </div>
  );
}

export default Achievements;
