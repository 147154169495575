import React, { useState } from 'react';
import { Breadcrumb, Form, Input, notification, Row, Col, Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons';
import { createSchool, isErrorHandlerSchool } from '../../../services/requests/createSchool';

function AddSchool() {
  const [state, setState] = useState({
    loading: false,
    isSaving: false,
  });

  // Fields to validations
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [whereState, setWhereState] = useState('');

  const history = useNavigate();

  const [form] = Form.useForm();

  const save = async (values) => {
    setState({
      ...state,
      isSaving: true,
    });

    const payload = {
      ...values,
    };

    const response = await createSchool(payload);

    if (isErrorHandlerSchool(response)) {
      notification.error({
        message: 'Create school',
        description: response.message,
      });

      setState({
        ...state,
        isSaving: false,
      });

      return;
    }

    notification.success({
      message: 'Create school',
      description: 'School was successfully created.',
    });

    history(`/admin/schools`);
  };

  const handleSubmit = async (values) => {
    await save(values);
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/schools" className="text-table text-sm font-Rubik font-normal">
            Schools
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Add School</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex overflow-y-auto flex-col gap-4 w-full">
          <Form name="school" form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="name"
              onChange={(e) => setName(e.target.value)}
              rules={[{ message: 'Please input the school name!', required: true }]}
              label="School Name"
              className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
            >
              <Input placeholder="School Name" />
            </Form.Item>

            <Row className="flex">
              <Col className="w-1/2">
                <Form.Item
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                  rules={[{ message: 'Please input the city!', required: true }]}
                  label="City"
                  className="[&>div>div>label]:text-sm mr-4 [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
                >
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col className="w-1/2">
                <Form.Item
                  name="state"
                  onChange={(e) => setWhereState(e.target.value)}
                  rules={[{ message: 'Please input the state!', required: true }]}
                  label="State"
                  className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
                >
                  <Input placeholder="State" />
                </Form.Item>
              </Col>
            </Row>
            <div className="flex items-center justify-center gap-3">
              <Form.Item>
                <NavLink
                  to="/admin/schools"
                  className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
                >
                  <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
                </NavLink>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!name || !city || !whereState || state.isSaving}
                  className={`min-w-[100px] ${
                    !name || !city || !whereState
                      ? 'text-[#9CA3AF] bg-[#F3F4F6]'
                      : 'bg-primary border-primary text-black'
                  } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
                >
                  <UilCheckCircle width={16} height={16} className="mr-1" />
                  {state.isSaving ? 'Loading...' : 'Save & Exit'}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AddSchool;
