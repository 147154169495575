import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Form, Input, Button, notification, Col, Spin } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  UilText,
  UilEdit,
  UilCheckCircle,
  UilTimesCircle,
  UilArrowToRight,
  UilClipboardNotes,
} from '@iconscout/react-unicons';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { updateAchievement, isErrorHandlerUpdateAchievement } from '../../../services/requests/updateAchievement';
import { getAchievementById } from '../../../services/requests/getAchievementById';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { alertModal } from '../../../components/modals/antd-modals';
import { Popover } from '../../../components/popup/popup';

import '../modal.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function EditAchievementDetails() {
  const [state, setState] = useState({
    loading: false,
    isSaving: false,
  });
  const { achievementId } = useParams();

  // Fields to validations
  const [summary, setSummary] = useState('');
  const [syllabus, setSyllabus] = useState('');
  const [courseCreditInstructor, setCourseCreditInstructor] = useState('');
  const [courseCreditSource, setCourseCreditSource] = useState('');
  const [externalLink, setExternalLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [form] = Form.useForm();

  const fetchAchievementById = useCallback(async () => {
    setIsLoading(true);

    const response = await getAchievementById(achievementId);

    setIsLoading(false);

    setSummary(response.summary);
    setSyllabus(response.syllabus);
    setCourseCreditInstructor(response.courseCreditInstructor);
    setCourseCreditSource(response.courseCreditSource);
    setExternalLink(response.externalLink);
  }, [achievementId]);

  useEffect(() => {
    async function fetchApiAchievement() {
      await fetchAchievementById();
    }

    fetchApiAchievement();
  }, []);

  const save = async (values, isNextButton) => {
    setState({
      ...state,
      isSaving: true,
    });

    const payload = {
      id: achievementId,
      ...values,
    };

    const response = await updateAchievement(payload);

    if (isErrorHandlerUpdateAchievement(response)) {
      notification.error({
        message: 'Update achievement',
        description: response.message,
      });

      setState({
        ...state,
        isSaving: false,
      });

      return;
    }

    notification.success({
      message: 'Update achievement',
      description: 'Achievement were successfully updated.',
    });

    if (isNextButton) {
      history(`/admin/achievements/edit/quiz/${achievementId}`);
    } else {
      history(`/admin/achievements`);
    }
  };

  const handleDiscard = () => {
    alertModal.confirm({
      content: 'Are you sure you want to exit without saving? Your current progress will be lost.',
      title: 'Discard changes?',
      cancelText: 'No, go back to editing',
      okText: 'Yes, exit without saving',
      className: 'footerStyle',
      onOk: () => {
        history('/admin/achievements');
      },
      onCancel() {},
    });
  };

  const handleSubmit = async (values) => {
    await save(values, false);
  };

  const handleSubmitNext = async () => {
    await save(
      {
        summary,
        syllabus,
        courseCreditSource,
        courseCreditInstructor,
        externalLink,
      },
      true,
    );
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/achievements" className="text-table text-sm font-Rubik font-normal">
            Achievements
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Edit Achievement</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[160px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <div className="flex flex-col bg-white rounded-md">
            <NavLink
              to={`/admin/achievements/edit/${achievementId}`}
              className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
            >
              <UilText width={20} height={20} className="mr-1" /> General
            </NavLink>
            <NavLink
              to={`/admin/achievements/edit/details/${achievementId}`}
              className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm"
            >
              <UilEdit width={20} height={20} className="mr-1" /> Details
            </NavLink>
            <NavLink
              to={`/admin/achievements/edit/quiz/${achievementId}`}
              className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
            >
              <UilClipboardNotes width={20} height={20} className="mr-1" /> Quiz
            </NavLink>
          </div>
        </div>

        <div className="flex overflow-y-auto flex-col gap-4 w-full">
          {isLoading && (
            <SpinerWraperStyle className="m-[25px]">
              <Spin indicator={antIcon} />
            </SpinerWraperStyle>
          )}
          {!isLoading && (
            <Form
              name="achievementDetail"
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              initialValues={{
                summary,
                externalLink,
                syllabus,
                courseCreditInstructor,
                courseCreditSource,
              }}
            >
              <Form.Item
                name="summary"
                onChange={(e) => setSummary(e.target.value)}
                rules={[{ message: 'Please input the summary!', required: true }]}
                label="Summary"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
              >
                <Input placeholder="Summary" />
              </Form.Item>

              <Form.Item
                name="syllabus"
                onChange={(e) => setSyllabus(e.target.value)}
                rules={[{ message: 'Please input the syllabus!', required: true }]}
                label="Syllabus"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
              >
                <Input placeholder="Syllabus" />
              </Form.Item>
              <p className="font-medium font-Rubik text-primary">Course Credits</p>
              <div className="flex ssm:flex-col sm:flex-col md:flex-col">
                <Col className="w-1/2 ssm:w-full sm:w-full md:w-full">
                  <Form.Item
                    name="courseCreditSource"
                    onChange={(e) => setCourseCreditSource(e.target.value)}
                    rules={[{ message: 'Please input the source!', required: true }]}
                    label="Source"
                    className="[&>div>div>label]:text-sm ssm:mr-0 sm:mr-0 md:mr-0 mr-4 [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
                  >
                    <Input placeholder="Source" />
                  </Form.Item>
                </Col>
                <Col className="w-1/2 ssm:w-full sm:w-full md:w-full">
                  <Form.Item
                    name="courseCreditInstructor"
                    onChange={(e) => setCourseCreditInstructor(e.target.value)}
                    rules={[{ message: 'Please input the instructor!', required: true }]}
                    label="Instructor"
                    className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
                  >
                    <Input placeholder="Instructor" />
                  </Form.Item>
                </Col>
              </div>
              <Form.Item
                name="externalLink"
                onChange={(e) => setExternalLink(e.target.value)}
                rules={[{ message: 'Please input the link!', required: true }]}
                // label="Link"
                label={
                  <span>
                    Link
                    <Popover placement="bottomLeft" content="The Link must be added with http:// or https://">
                      <QuestionCircleOutlined style={{ marginLeft: 4, cursor: 'pointer' }} />
                    </Popover>
                  </span>
                }
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium font-Rubik"
              >
                <Input placeholder="Link" />
              </Form.Item>
              <div className="flex items-center justify-center gap-3">
                <Form.Item>
                  <NavLink
                    onClick={handleDiscard}
                    className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
                  >
                    <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
                  </NavLink>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleSubmitNext}
                    type="primary"
                    disabled={
                      !summary ||
                      !syllabus ||
                      !courseCreditInstructor ||
                      !courseCreditSource ||
                      !externalLink ||
                      state.isSaving
                    }
                    className="bg-white text-[#A7800C] max-w-[150px] border-gray-200 border-1 text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
                  >
                    <UilArrowToRight width={16} height={16} className="mr-1" />
                    {state.isSaving ? 'Loading...' : 'Next'}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={
                      !summary ||
                      !syllabus ||
                      !courseCreditInstructor ||
                      !courseCreditSource ||
                      !externalLink ||
                      state.isSaving
                    }
                    className={`min-w-[100px] ${
                      !summary || !syllabus || !courseCreditInstructor || !courseCreditSource || !externalLink
                        ? 'text-[#9CA3AF] bg-[#F3F4F6]'
                        : 'bg-primary border-primary text-black'
                    } border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
                  >
                    <UilCheckCircle width={16} height={16} className="mr-1" />
                    {state.isSaving ? 'Loading...' : 'Save & Exit'}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditAchievementDetails;
