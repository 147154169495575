import api from '../api';

function removePropertiesFromArray(arr) {
  return arr.map((obj) => {
    // Create a copy of the object with specified properties removed
    const newObj = { ...obj };
    delete newObj.id;
    delete newObj.completed;
    delete newObj.fixed;

    // Recursively process nested objects in answers array
    if (newObj.answers && Array.isArray(newObj.answers)) {
      newObj.answers = removePropertiesFromArray(newObj.answers);
    }

    return newObj;
  });
}

export const createQuiz = async (props) => {
  try {
    const newPayload = removePropertiesFromArray(props);
    const response = await api.post('/admin/achievement/test-question', {
      questions: [...newPayload],
    });

    return response.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerQuiz = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
