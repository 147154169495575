import api from '../api';

export const deleteAchievementById = async (achievementId) => {
  try {
    const response = await api.delete('/admin/achievement', {
      params: {
        id: achievementId,
      },
    });

    return response.data.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerDeleteAchievement = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
