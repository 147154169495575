import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Collapse, InputNumber, Spin, notification } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  UilPostcard,
  UilFileCheck,
  UilRocket,
  UilUsdCircle,
  UilTimesCircle,
  UilCheckCircle,
  UilAngleUp,
  UilAngleDown,
  UilClockThree,
  UilDollarSign,
} from '@iconscout/react-unicons';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { getAchievementsPriceByCompanyId } from '../../../services/requests/getAchievementsPriceByCompanyId';
import { createAchievementPriceCompany } from '../../../services/requests/createAchievementPriceCompany';
import { splitArrayInSmallArrays } from '../../../utility/splitArrays';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const { Panel } = Collapse;

function AddAchievements() {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { companyId } = useParams();
  const [roles, setRoles] = useState([]);
  const [selectedAchievements, setSelectedAchievements] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    async function fetchRolesAndAchievements() {
      const response = await getAchievementsPriceByCompanyId(companyId);

      setRoles(response);

      setIsLoading(false);
    }

    fetchRolesAndAchievements();
  }, [companyId]);

  const handleSubmit = async () => {
    setIsSaving(true);

    try {
      const splitArrays = splitArrayInSmallArrays(selectedAchievements, 15);

      splitArrays.forEach(async (values) => {
        await createAchievementPriceCompany(values);
      });

      notification.success({
        message: 'Update Price',
        description: 'Achievements was successfully updated for the company.',
      });
    } catch (error) {
      notification.error({
        message: 'Update Price',
        description: 'Something went wrong. Please try again.',
      });

      setIsSaving(false);
    } finally {
      history(`/admin/companies`);
    }
  };

  const currencyParser = (val) => {
    try {
      // for when the input gets clears
      if (typeof val === 'string' && !val.length) {
        val = '0.0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat('en-us').format(1111).replace(/1/g, '');
      const decimal = new Intl.NumberFormat('en-us').format(1.1).replace(/1/g, '');
      let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      throw new Error(error);
    }
  };

  const currencyFormatter = () => (value) => {
    return new Intl.NumberFormat('en-us', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const handleAchievementToggle = (companyRoleAchievementId, value) => {
    const regex = /^(\d{1,5}(\.\d{1,2})?)$/;

    const existingIndex = selectedAchievements.findIndex(
      (item) => item.companyRoleAchievementId === companyRoleAchievementId,
    );

    if (existingIndex !== -1) {
      const updatedAchievements = [...selectedAchievements];
      const achievementIndex = updatedAchievements[existingIndex].companyRoleAchievementId;

      if (achievementIndex) {
        if (!value) {
          updatedAchievements.splice(existingIndex, 1);
        } else if (regex.test(value)) {
          updatedAchievements[existingIndex].earn = parseFloat(value);
        }
      }

      setSelectedAchievements(updatedAchievements);
    } else if (value && regex.test(value)) {
      setSelectedAchievements([...selectedAchievements, { companyRoleAchievementId, earn: parseFloat(value) }]);
    }
  };

  const returnExtraIcon = (companyRolesAchievementIds) => {
    const completed = selectedAchievements.some((selected) =>
      companyRolesAchievementIds.includes(selected.companyRoleAchievementId),
    );

    if (completed) {
      return (
        <div className="text-green-500">
          <UilCheckCircle />
        </div>
      );
    }

    return (
      <div className="text-[#FB923C]">
        <UilClockThree />
      </div>
    );
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/companies" className="text-table text-sm font-Rubik font-normal">
            Companies
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Add Company</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[268px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilPostcard width={20} height={20} className="mr-1" /> Company Profile
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilFileCheck width={20} height={20} className="mr-1" /> Roles
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilDollarSign width={20} height={20} className="mr-1" /> Max Payout
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilRocket width={20} height={20} className="mr-1" /> Achievements
          </p>
          <p className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm">
            <UilUsdCircle width={20} height={20} className="mr-1" /> Pricing
          </p>
        </div>

        {isLoading && (
          <SpinerWraperStyle className="m-[25px]">
            <Spin indicator={antIcon} />
          </SpinerWraperStyle>
        )}

        {!isLoading && (
          <div className="flex overflow-y-auto flex-col gap-4 w-full">
            {roles.map((role) => (
              <Collapse
                expandIcon={({ isActive }) => (isActive ? <UilAngleUp /> : <UilAngleDown />)}
                key={role.id}
                defaultActiveKey={roles[0].id}
              >
                <Panel
                  extra={<div>{returnExtraIcon(role.achievements.map((ach) => ach.companyRoleAchievementId))}</div>}
                  header={<div className="font-Jost font-medium text-xl text-[#A7800C]">{role.title}</div>}
                  key={role.id}
                >
                  <div
                    key={role.id}
                    className="grid ssm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-3 4xl:grid-cols-3 5xl:grid-cols-5 gap-4"
                  >
                    {role.achievements.map((achievement) => (
                      <div
                        key={achievement.companyRoleAchievementId}
                        className="flex flex-col border p-0 border-gray-200 rounded-xl ssm:w-full sm:w-full md:w-full lg:w-full h-[180px] overflow-hidden"
                      >
                        <div className="p-2 flex flex-row h-full gap-2">
                          <div className="ssm:hidden sm:hidden md:hidden flex flex-col">
                            <img
                              src={achievement.achievementImage || '/achievement.png'}
                              alt="achievement"
                              className="h-full w-full object-fill"
                            />
                          </div>

                          <div className="flex flex-col w-full justify-between">
                            <h1 className="text-ellipsis text-sm font-semibold font-Rubik">
                              {achievement.achievementTitle}
                            </h1>

                            <p className="line-clamp-2 m-0 text-xs font-Rubik font-normal">
                              {achievement.achievementSummary}
                            </p>

                            <div>
                              <p className="text-[14px] m-0 font-semibold">Bonus</p>
                              <div className="flex gap-2 items-center pr-2 justify-center">
                                <InputNumber
                                  defaultValue={Number(achievement.earn)}
                                  style={{
                                    width: 400,
                                    marginRight: '1rem',
                                  }}
                                  formatter={currencyFormatter()}
                                  parser={currencyParser}
                                  onChange={(e) => {
                                    handleAchievementToggle(achievement.companyRoleAchievementId, e);
                                  }}
                                  max={99999.99}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            ))}
            <div className="flex items-center justify-center gap-3">
              <Button
                onClick={() => history(`/admin/companies`)}
                className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
              </Button>

              <Button
                onClick={handleSubmit}
                disabled={!selectedAchievements.length || isSaving}
                className={`min-w-[100px] ${
                  !selectedAchievements.length ? 'text-[#9CA3AF] bg-[#F3F4F6]' : 'bg-primary border-primary text-black'
                } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
              >
                <UilCheckCircle width={16} height={16} className="mr-1" /> {isSaving ? 'Loading...' : 'Save & Exit'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddAchievements;
