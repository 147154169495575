import api from '../api';

export const updateQuiz = async ({ achievementId, questions }) => {
  try {
    const response = await api.put(`/admin/achievement/test-question?achievementId=${achievementId}`, {
      questions: questions.map((question) => ({
        id: question.id,
        question: question.question,
        answers: question.answers.map((answer) => ({
          correctAnswer: answer.correctAnswer,
          option: answer.option,
        })),
      })),
    });

    return response.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerQuiz = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
