import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Button, Collapse, Input } from 'antd';
import {
  UilCheckCircle,
  UilClockThree,
  UilAngleUp,
  UilAngleDown,
  UilPlus,
  UilTrash,
  UilCheck,
} from '@iconscout/react-unicons';

const { Panel } = Collapse;

const AccordionQuestion = forwardRef(({ question, idx }, ref) => {
  const [answerQuestions, setAnswerQuestions] = useState(question.answers);
  const [userQuestion, setUserQuestion] = useState({
    id: question.id,
    achievementId: question.achievementId,
    question: question.question,
    completed: question.completed,
  });

  const handleAddAnswer = () => {
    setAnswerQuestions([...answerQuestions, { optionId: uuidv4(), option: '', correctAnswer: false, fixed: false }]);
  };

  const handleDeleteAnswer = (answerId) => {
    setAnswerQuestions((state) => state.filter((answer) => answer.optionId !== answerId));
  };

  const handleSetQuestion = (text) => {
    setUserQuestion({
      ...userQuestion,
      question: text,
      completed: !!text,
    });
  };

  const handleChangeAnswer = (answerId, text) => {
    setAnswerQuestions((state) => {
      const answer = state.findIndex((t) => t.optionId === answerId);

      if (answer !== -1) {
        state[answer].option = text;
      }

      return [...state];
    });
  };

  const handleCorrectAnswer = (answerId) => {
    setAnswerQuestions((state) => {
      const newArray = state.map((a) => ({ ...a, correctAnswer: false }));
      const answer = newArray.findIndex((t) => t.optionId === answerId);

      if (answer !== -1) {
        newArray[answer].correctAnswer = true;
      }

      return [...newArray];
    });
  };

  useEffect(() => {
    setUserQuestion((state) => {
      const completed =
        answerQuestions.every((answer) => answer.option) &&
        state.question &&
        answerQuestions.some((answer) => answer.correctAnswer);

      return {
        ...state,
        completed,
      };
    });
  }, [answerQuestions]);

  const returnExtraIcon = useCallback(() => {
    const { completed } = userQuestion;

    if (completed) {
      return (
        <div className="text-[#22C55E]">
          <UilCheckCircle />
        </div>
      );
    }

    return (
      <div className="text-[#FB923C]">
        <UilClockThree />
      </div>
    );
  }, [userQuestion]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getQuestion() {
          return {
            ...userQuestion,
            answers: answerQuestions,
          };
        },
      };
    },
    [userQuestion, answerQuestions],
  );

  return (
    <Collapse
      expandIcon={({ isActive }) => (isActive ? <UilAngleUp /> : <UilAngleDown />)}
      key={`${question.id}-question`}
    >
      <Panel
        extra={<div>{returnExtraIcon(question)}</div>}
        header={<div className="font-Jost font-medium text-xl text-[#A7800C]">Question {idx + 1}</div>}
      >
        <div className="flex flex-col gap-5">
          <p className="m-0 text-[#52525B] text-sm font-Rubik font-medium">Question title</p>
          <Input defaultValue={question.question} onChange={(e) => handleSetQuestion(e.target.value)} />
          <div className="flex w-full justify-between items-center">
            <p className="m-0 text-[#52525B] text-sm font-Rubik font-medium">Answers</p>
            <Button
              onClick={handleAddAnswer}
              className="w-[32px] h-[32px] bg-primary p-2 border-solid border-1 border-primary text-sm text-white font-medium flex items-center justify-center rounded-[4px]"
            >
              <UilPlus width={16} height={16} />
            </Button>
          </div>
          {answerQuestions.map((answer, index) => (
            <div key={`${answer.optionId}-${index}`} className="flex gap-2">
              <Input
                placeholder="Enter an answer"
                defaultValue={answer.option}
                onChange={(e) => handleChangeAnswer(answer.optionId, e.target.value)}
              />
              <Button
                onClick={() => handleCorrectAnswer(answer.optionId)}
                className={`${
                  !answer.correctAnswer
                    ? 'text-[#9CA3AF] bg-[#F3F4F6] border-[#F3F4F6]'
                    : 'bg-primary border-primary text-white'
                }  p-4 h-[auto] border-solid border-1  text-sm  font-medium flex items-center justify-center rounded-[4px]`}
              >
                <UilCheck width={16} height={16} />
              </Button>
              <Button
                disabled={index <= 1}
                onClick={() => handleDeleteAnswer(answer.optionId)}
                className={` ${
                  index <= 1 ? 'text-[#9CA3AF] bg-[#F3F4F6] border-[#F3F4F6]' : 'bg-primary border-primary text-white'
                } p-4 h-[auto] border-solid border-1 text-sm font-medium flex items-center justify-center rounded-[4px]`}
              >
                <UilTrash width={16} height={16} />
              </Button>
            </div>
          ))}
        </div>
      </Panel>
    </Collapse>
  );
});

AccordionQuestion.propTypes = {
  question: PropTypes.object,
  idx: PropTypes.number,
};

export default AccordionQuestion;
