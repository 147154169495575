import React, { useMemo, useState } from 'react';
import { Breadcrumb, Button, notification } from 'antd';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { UilText, UilEdit, UilClipboardNotes, UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons';
import getQuiz from './getQuiz';
import AccordionQuestion from './AccordionQuestion';
import { createQuiz } from '../../../services/requests/createQuiz';
import { alertModal } from '../../../components/modals/antd-modals';
import '../modal.css';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AddQuiz() {
  const [isSaving, setIsSaving] = useState(false);
  const { achievementId } = useParams();
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const history = useNavigate();
  const isEdit = useQuery().get('isEdit');

  const questions = useMemo(() => getQuiz(achievementId), [achievementId]);

  const save = async () => {
    setIsSaving(true);

    try {
      await createQuiz(completedQuestions);

      notification.success({
        message: 'Create Achievements',
        description: 'Quiz was successfully created for the achievement.',
      });
    } catch (error) {
      notification.error({
        message: 'Create Achievements',
        description: 'Something went wrong. Please try again.',
      });

      setIsSaving(false);

      return;
    } finally {
      history(`/admin/achievements`);
    }
  };

  const handleSubmit = async () => {
    await save();
  };

  const handleCompleteQuestion = (q) => {
    const alreadyAdded = completedQuestions.findIndex((c) => c.id === q.id);
    const questionsNew = completedQuestions;

    if (alreadyAdded !== -1) {
      questionsNew.splice(alreadyAdded, 1);
    }

    setCompletedQuestions([...questionsNew, q]);
  };

  const handleDiscard = () => {
    alertModal.confirm({
      content: 'Are you sure you want to exit without saving? Your current progress will be lost.',
      title: 'Discard changes?',
      cancelText: 'No, go back to editing',
      okText: 'Yes, exit without saving',
      className: 'footerStyle',
      onOk: () => {
        history('/admin/achievements');
      },
      onCancel() {},
    });
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/achievements" className="text-table text-sm font-Rubik font-normal">
            Achievements
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">
          {isEdit ? 'Edit Achievement' : 'Create Achievement'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[160px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <div className="flex flex-col bg-white rounded-md">
            {!isEdit ? (
              <>
                <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
                  <UilText width={20} height={20} className="mr-1" /> General
                </p>
                <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
                  <UilEdit width={20} height={20} className="mr-1" /> Details
                </p>
                <p className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm">
                  <UilClipboardNotes width={20} height={20} className="mr-1" /> Quiz
                </p>
              </>
            ) : (
              <>
                <NavLink
                  to={`/admin/achievements/edit/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
                >
                  <UilText width={20} height={20} className="mr-1" /> General
                </NavLink>
                <NavLink
                  to={`/admin/achievements/edit/details/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik"
                >
                  <UilEdit width={20} height={20} className="mr-1" /> Details
                </NavLink>
                <NavLink
                  to={`/admin/achievements/edit/quiz/${achievementId}`}
                  className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm"
                >
                  <UilClipboardNotes width={20} height={20} className="mr-1" /> Quiz
                </NavLink>
              </>
            )}
          </div>
        </div>

        <div className="flex overflow-y-auto flex-col gap-4 w-full">
          {questions.map((question, idx) => (
            <AccordionQuestion
              question={question}
              idx={idx}
              key={question.id}
              onComplete={handleCompleteQuestion}
              onIncomplete={(q) => setCompletedQuestions((state) => state.filter((r) => r.id !== q.id))}
            />
          ))}
          <div className="flex items-center justify-center gap-3">
            <Button
              onClick={handleDiscard}
              className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
            >
              <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
            </Button>

            <Button
              onClick={handleSubmit}
              disabled={!(completedQuestions.length === questions.length) || isSaving}
              className={`min-w-[100px] ${
                !(completedQuestions.length === questions.length)
                  ? 'text-[#9CA3AF] bg-[#F3F4F6]'
                  : 'bg-primary border-primary text-black'
              } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
            >
              <UilCheckCircle width={16} height={16} className="mr-1" />
              {isSaving ? 'Loading...' : 'Save & Exit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddQuiz;
