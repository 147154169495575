import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AddRoles from '../../container/companies/AddRoles';
import AddMaxPayout from '../../container/companies/AddMaxPayout';
import AddAchievements from '../../container/companies/AddAchievements';
import AddAchievement from '../../container/achievements/AddAchievement';
import AddAchievementDetails from '../../container/achievements/AddAchievementDetails';
import AddAchievementsPrice from '../../container/companies/AddAchievementsPrice';
import EditRoles from '../../container/companies/EditRoles';
import EditAchievements from '../../container/companies/EditAchievements';
import EditAchievementsPrice from '../../container/companies/EditAchievementsPrice';
import EditCompanyRole from '../../container/roles/EditCompanyRole';
import Achievements from '../../container/achievements';
import EditAchievement from '../../container/achievements/EditAchievement';
import EditAchievementDetails from '../../container/achievements/EditAchievementDetails';
import AddQuiz from '../../container/achievements/AddQuiz';
import EditQuiz from '../../container/achievements/EditQuiz';
import Schools from '../../container/schools';
import AddSchool from '../../container/schools/AddSchool';
import EditMaxPayout from '../../container/companies/EditMaxPayout';

const Dashboard = lazy(() => import('../../container/dashboard'));
const NotFound = lazy(() => import('../../container/pages/404'));
const Companies = lazy(() => import('../../container/companies'));
const Families = lazy(() => import('../../container/families'));
const AddFamilyRole = lazy(() => import('../../container/families/AddFamilyRole'));
const EditFamilyRole = lazy(() => import('../../container/families/EditFamilyRole'));
const AddRoleInCareerPath = lazy(() => import('../../container/families/AddRoles'));
const EditRoleInCareerPath = lazy(() => import('../../container/families/EditRoles'));
const AddAchievementsPriceRole = lazy(() => import('../../container/roles/AddAchievementsPrice'));
const EditAchievementsPriceRole = lazy(() => import('../../container/roles/EditAchievementsPrice'));
const AddCompany = lazy(() => import('../../container/companies/AddCompany'));
const EditCompany = lazy(() => import('../../container/companies/EditCompany'));
const Roles = lazy(() => import('../../container/roles'));
const AddRole = lazy(() => import('../../container/roles/AddRole'));
const EditRole = lazy(() => import('../../container/roles/EditRole'));
const AddCompanyRole = lazy(() => import('../../container/roles/AddCompanyRole'));
const AddAchievementsRole = lazy(() => import('../../container/roles/AddAchievements'));
const EditAchievementsRole = lazy(() => import('../../container/roles/EditAchievements'));
const Students = lazy(() => import('../../container/students'));
const Reports = lazy(() => import('../../container/reports'));
const Manager = lazy(() => import('../../container/manager'));

function DashboardRoutes() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="schools">
        <Route path="add" element={<AddSchool />} />
        <Route index element={<Schools />} />
      </Route>
      <Route path="students">
        <Route index element={<Students />} />
      </Route>
      <Route path="reports">
        <Route index element={<Reports />} />
      </Route>
      <Route path="manager">
        <Route index element={<Manager />} />
      </Route>
      <Route path="achievements">
        <Route index element={<Achievements />} />
        <Route path="add" element={<AddAchievement />} />
        <Route path="new/details/:achievementId" element={<AddAchievementDetails />} />
        <Route path="new/quiz/:achievementId" element={<AddQuiz />} />
        <Route path="edit/:achievementId" element={<EditAchievement />} />
        <Route path="edit/details/:achievementId" element={<EditAchievementDetails />} />
        <Route path="edit/quiz/:achievementId" element={<EditQuiz />} />
      </Route>
      <Route path="companies">
        <Route path="add" element={<AddCompany />} />
        <Route path="edit/:companyId" element={<EditCompany />} />
        <Route path="edit/roles/:companyId" element={<EditRoles />} />
        <Route path="edit/achievements/:companyId" element={<EditAchievements />} />
        <Route path="edit/achievements/price/:companyId" element={<EditAchievementsPrice />} />
        <Route path="edit/payout/:companyId" element={<EditMaxPayout />} />
        <Route path="new/roles/:companyId" element={<AddRoles />} />
        <Route path="new/achievements/:companyId" element={<AddAchievements />} />
        <Route path="new/achievements/price/:companyId" element={<AddAchievementsPrice />} />
        <Route path="new/payout/:companyId" element={<AddMaxPayout />} />

        <Route index element={<Companies />} />
      </Route>
      <Route path="families">
        <Route path="add" element={<AddFamilyRole />} />
        <Route path="new/roles/:familyRoleId" element={<AddRoleInCareerPath />} />
        <Route path="edit/roles/:familyRoleId" element={<EditRoleInCareerPath />} />
        <Route path="edit/:familyRoleId" element={<EditFamilyRole />} />
        {/* <Route path="edit/:companyId" element={<EditCompany />} />
        <Route path="edit/roles/:companyId" element={<EditRoles />} />
        <Route path="edit/achievements/:companyId" element={<EditAchievements />} />
        <Route path="edit/achievements/price/:companyId" element={<EditAchievementsPrice />} />
        <Route path="edit/payout/:companyId" element={<EditMaxPayout />} />
        <Route path="new/roles/:companyId" element={<AddRoles />} />
        <Route path="new/achievements/:companyId" element={<AddAchievements />} />
        <Route path="new/achievements/price/:companyId" element={<AddAchievementsPrice />} />
        <Route path="new/payout/:companyId" element={<AddMaxPayout />} /> */}
        <Route index element={<Families />} />
      </Route>
      <Route path="roles">
        <Route path="edit/:roleId" element={<EditRole />} />
        <Route path="edit/companies/:roleId" element={<EditCompanyRole />} />
        <Route path="edit/achievements/:roleId" element={<EditAchievementsRole />} />
        <Route path="edit/achievements/price/:roleId" element={<EditAchievementsPriceRole />} />
        <Route path="new/companies/:roleId" element={<AddCompanyRole />} />
        <Route path="new/achievements/:roleId" element={<AddAchievementsRole />} />
        <Route path="new/achievements/price/:roleId" element={<AddAchievementsPriceRole />} />
        <Route index element={<Roles />} />
        <Route path="add" element={<AddRole />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DashboardRoutes;
