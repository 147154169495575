import api from '../api';

export const updateRoleMaxPayout = async (props) => {
  try {
    const response = await api.put('/admin/max-payout', {
      maxPayPayload: props,
    });

    return response.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerUpdateRoleMaxPayout = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
