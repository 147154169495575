import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Collapse, Spin, notification } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  UilPostcard,
  UilFileCheck,
  UilRocket,
  UilUsdCircle,
  UilCheckCircle,
  UilTimesCircle,
  UilClockThree,
  UilAngleUp,
  UilAngleDown,
  UilArrowToRight,
  UilDollarSign,
} from '@iconscout/react-unicons';
import { getAchievementsByCompanyId } from '../../../services/requests/getAchievementsByCompanyId';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { Checkbox } from '../../../components/checkbox/checkbox';
import { createAchievementCompany } from '../../../services/requests/createAchievementCompany';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const { Panel } = Collapse;

function AddAchievements() {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { companyId } = useParams();
  const [roles, setRoles] = useState([]);
  const [selectedAchievements, setSelectedAchievements] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    async function fetchRolesAndAchievements() {
      const response = await getAchievementsByCompanyId(companyId);

      setRoles(response.roles);

      setIsLoading(false);
    }

    fetchRolesAndAchievements();
  }, [companyId]);

  const save = async (isNextButton) => {
    const hasAllCompanyRolesIds = roles.every((data) =>
      selectedAchievements.some((ach) => ach.companyRoleId === data.companyRoleId),
    );

    if (!hasAllCompanyRolesIds) {
      notification.error({
        message: 'Create Achievements',
        description: 'Select at least one achievement for each role.',
      });

      return;
    }

    setIsSaving(true);

    const promises = selectedAchievements.flatMap((entry) => createAchievementCompany(entry));

    try {
      await Promise.all(promises);

      notification.success({
        message: 'Create Achievements',
        description: 'Achievements was successfully created for the company.',
      });
    } catch (error) {
      notification.error({
        message: 'Create Achievements',
        description: 'Something went wrong. Please try again.',
      });

      setIsSaving(false);

      return;
    } finally {
      if (isNextButton) {
        history(`/admin/companies/new/achievements/price/${companyId}`);
      } else {
        history(`/admin/companies`);
      }
    }
  };

  const handleSubmitNext = async () => {
    await save(true);
  };

  const handleSubmit = async () => {
    await save(false);
  };

  const handleAchievementToggle = (companyRoleId, achievementId, checked) => {
    const existingIndex = selectedAchievements.findIndex((item) => item.companyRoleId === companyRoleId);

    if (existingIndex !== -1) {
      const updatedAchievements = [...selectedAchievements];
      const achievementIndex = updatedAchievements[existingIndex].achievements.indexOf(achievementId);

      if (achievementIndex !== -1) {
        if (!checked) {
          updatedAchievements[existingIndex].achievements.splice(achievementIndex, 1);

          if (updatedAchievements[existingIndex].achievements.length === 0) {
            updatedAchievements.splice(existingIndex, 1);
          }
        }
      } else if (checked) {
        updatedAchievements[existingIndex].achievements.push(achievementId);
      }

      setSelectedAchievements(updatedAchievements);
    } else if (checked) {
      setSelectedAchievements([...selectedAchievements, { companyRoleId, achievements: [achievementId] }]);
    }
  };

  const returnExtraIcon = (companyRoleId) => {
    const completed = selectedAchievements.some((selected) => selected.companyRoleId === companyRoleId);

    if (completed) {
      return (
        <div className="text-[#22C55E]">
          <UilCheckCircle />
        </div>
      );
    }

    return (
      <div className="text-[#FB923C]">
        <UilClockThree />
      </div>
    );
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/companies" className="text-table text-sm font-Rubik font-normal">
            Companies
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Add Company</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[268px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilPostcard width={20} height={20} className="mr-1" /> Company Profile
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilFileCheck width={20} height={20} className="mr-1" /> Roles
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilDollarSign width={20} height={20} className="mr-1" /> Max Payout
          </p>
          <p className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm">
            <UilRocket width={20} height={20} className="mr-1" /> Achievements
          </p>
          <p className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik">
            <UilUsdCircle width={20} height={20} className="mr-1" /> Pricing
          </p>
        </div>

        {isLoading && (
          <SpinerWraperStyle className="m-[25px]">
            <Spin indicator={antIcon} />
          </SpinerWraperStyle>
        )}

        {!isLoading && (
          <div className="flex overflow-y-auto flex-col gap-4 w-full">
            {roles.map((role) => (
              <Collapse
                expandIcon={({ isActive }) => (isActive ? <UilAngleUp /> : <UilAngleDown />)}
                key={role.id}
                defaultActiveKey={roles[0].id}
              >
                <Panel
                  extra={<div>{returnExtraIcon(role.companyRoleId)}</div>}
                  header={<div className="font-Jost font-medium text-xl text-[#A7800C]">{role.title}</div>}
                  key={role.id}
                >
                  <div
                    key={role.id}
                    className="grid ssm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 gap-4"
                  >
                    {role.achievements.map((achievement) => (
                      <div
                        key={achievement.id}
                        className="flex flex-col border p-0 border-gray-200 rounded-xl overflow-hidden"
                      >
                        <div className="flex items-center px-2 pt-2">
                          <Checkbox
                            checked={selectedAchievements.some(
                              (selected) =>
                                selected.companyRoleId === role.companyRoleId &&
                                selected.achievements.includes(achievement.id),
                            )}
                            onChange={(checked) => handleAchievementToggle(role.companyRoleId, achievement.id, checked)}
                          >
                            <h1 className="text-ellipsis text-sm font-semibold font-Rubik">{achievement.title}</h1>
                          </Checkbox>
                        </div>

                        <hr />

                        <div className="p-2 flex max-h-[200px]">
                          <div className="ssm:hidden sm:hidden md:hidden">
                            <img
                              src={achievement.achievementImage || '/achievement.png'}
                              alt="achievement"
                              className="min-h-[140px] max-w-[100px] object-cover"
                            />
                          </div>
                          <div
                            className="line-clamp-6 m-0 ml-2 text-xs font-Rubik font-normal"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: achievement.overview }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            ))}
            <div className="flex items-center justify-center gap-3">
              <Button
                onClick={() => history(`/admin/companies`)}
                className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
              </Button>

              <Button
                onClick={handleSubmitNext}
                disabled={selectedAchievements.length !== roles.length || isSaving}
                className="bg-white text-[#A7800C] max-w-[150px] border-gray-200 border-1 text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilArrowToRight width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Next'}
              </Button>

              <Button
                onClick={handleSubmit}
                disabled={selectedAchievements.length !== roles.length || isSaving}
                className={`min-w-[100px] ${
                  selectedAchievements.length !== roles.length
                    ? 'text-[#9CA3AF] bg-[#F3F4F6]'
                    : 'bg-primary border-primary text-black'
                } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
              >
                <UilCheckCircle width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Save & Exit'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddAchievements;
