import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Spin, notification, InputNumber } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  UilPostcard,
  UilFileCheck,
  UilRocket,
  UilUsdCircle,
  UilTimesCircle,
  UilCheckCircle,
  UilArrowToRight,
  UilDollarSign,
} from '@iconscout/react-unicons';
import { SpinerWraperStyle } from '../../ui-elements/ui-elements-styled';
import { Checkbox } from '../../../components/checkbox/checkbox';
import { getRolesByCompanyId } from '../../../services/requests/getRolesByCompanyId';
import { isErrorHandlerUpdateRoleMaxPayout, updateRoleMaxPayout } from '../../../services/requests/updateRoleMaxPayout';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

function EditMaxPayout() {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const history = useNavigate();
  const { companyId } = useParams();

  useEffect(() => {
    async function fetchRoles() {
      const [response] = await Promise.all([getRolesByCompanyId(companyId)]);

      setRoles(response);
      setIsLoading(false);
    }

    fetchRoles();
  }, [companyId]);

  const save = async (isNextButton) => {
    const payload = roles.map((role) => ({
      companyRoleId: role.companyRoleId,
      maxPayout: role.maxPayout,
    }));

    if (payload.some((role) => !role.maxPayout)) {
      notification.error({
        message: 'Edit Roles',
        description: 'All roles should have a maximum payout',
      });

      return;
    }

    setIsSaving(true);

    const response = await updateRoleMaxPayout(payload);

    if (isErrorHandlerUpdateRoleMaxPayout(response)) {
      notification.error({
        message: 'Edit Roles',
        description: 'Something went wrong, please try again',
      });

      return;
    }

    notification.success({
      message: 'Edit Roles',
      description: 'Roles was successfully updated for the company.',
    });

    if (isNextButton) {
      history(`/admin/companies/edit/achievements/${companyId}`);
    } else {
      history(`/admin/companies`);
    }
  };

  const handleSubmit = async () => {
    await save(false);
  };

  const handleSubmitNext = async () => {
    await save(true);
  };

  const currencyParser = (val) => {
    try {
      // for when the input gets clears
      if (typeof val === 'string' && !val.length) {
        val = '0.0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat('en-us').format(1111).replace(/1/g, '');
      const decimal = new Intl.NumberFormat('en-us').format(1.1).replace(/1/g, '');
      let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      throw new Error(error);
    }
  };

  const currencyFormatter = () => (value) => {
    return new Intl.NumberFormat('en-us', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const handleAchievementToggle = (companyRoleId, value) => {
    const regex = /^(\d{1,5}(\.\d{1,2})?)$/;

    const existingIndex = roles.findIndex((item) => item.companyRoleId === companyRoleId);

    if (existingIndex !== -1) {
      const updatedAchievements = [...roles];
      const achievementIndex = updatedAchievements[existingIndex].companyRoleId;

      if (achievementIndex) {
        if (regex.test(value)) {
          updatedAchievements[existingIndex].maxPayout = parseFloat(value);
        }
      }

      setRoles(updatedAchievements);
    } else if (value && regex.test(value)) {
      setRoles([...roles, { companyRoleId, maxPayout: parseFloat(value) }]);
    }
  };

  return (
    <div className="flex flex-col p-5 gap-5">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <NavLink to="/admin/companies" className="text-table text-sm font-Rubik font-normal">
            Companies
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-primary text-sm font-Rubik font-normal">Edit Company</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex ssm:flex-col sm:flex-col md:flex-col ssm:gap-y-5 sm:gap-y-5 md:gap-y-5 gap-x-5">
        <div className="flex flex-col bg-white rounded-md max-h-[268px] w-[220px] ssm:w-full sm:w-full md:w-full">
          <NavLink
            to={`/admin/companies/edit/${companyId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilPostcard width={20} height={20} className="mr-1" /> Company Profile
          </NavLink>
          <NavLink
            to={`/admin/companies/edit/roles/${companyId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilFileCheck width={20} height={20} className="mr-1" /> Roles
          </NavLink>
          <NavLink
            to={`/admin/companies/edit/payout/${companyId}`}
            className="p-4 gap-2 m-0 flex items-center bg-yellow-50 text-primary font-medium font-Rubik text-sm cursor-pointer"
          >
            <UilDollarSign width={20} height={20} className="mr-1" /> Max Payout
          </NavLink>
          <NavLink
            to={`/admin/companies/edit/achievements/${companyId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilRocket width={20} height={20} className="mr-1" /> Achievements
          </NavLink>
          <NavLink
            to={`/admin/companies/edit/achievements/price/${companyId}`}
            className="p-4 gap-2 m-0 flex items-center text-[#52525B] text-sm font-normal font-Rubik hover:font-medium hover:bg-yellow-50 hover:text-primary cursor-pointer"
          >
            <UilUsdCircle width={20} height={20} className="mr-1" /> Pricing
          </NavLink>
        </div>

        {isLoading && (
          <SpinerWraperStyle className="m-[25px]">
            <Spin indicator={antIcon} />
          </SpinerWraperStyle>
        )}

        {!isLoading && (
          <div className="flex overflow-y-auto flex-col gap-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3 4xl:grid-cols-4 5xl:grid-cols-5 gap-4">
              {roles.map((role) => (
                <div
                  key={role.id}
                  className="bg-white m-0 p-0 text-[15px] mb-[25px] rounded-10 relative min-h-[220px] flex flex-col"
                >
                  <div className="p-4">
                    <Checkbox checked onChange={() => {}} />
                  </div>

                  <div className="px-4 flex items-center text-dark font-medium text-[17px]">
                    <div className="mb-0 inline-block overflow-hidden whitespace-nowrap text-ellipsis text-[18px] font-semibold">
                      {role.title}
                    </div>
                  </div>
                  <div className="px-4 mt-2 flex items-center text-dark text-sm">
                    <div className="line-clamp-4">{role.previewDescription || ''}</div>
                  </div>
                  <div className="px-4 my-2 text-dark font-medium inline-block overflow-hidden whitespace-nowrap text-ellipsis text-sm">
                    Max Payout:
                  </div>
                  <InputNumber
                    defaultValue={role.maxPayout}
                    style={{
                      marginLeft: '1rem',
                      marginBottom: '1rem',
                      width: '80%',
                    }}
                    formatter={currencyFormatter()}
                    parser={currencyParser}
                    onChange={(e) => {
                      handleAchievementToggle(role.companyRoleId, e);
                    }}
                    max={99999.99}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center gap-3">
              <NavLink
                to="/admin/companies"
                className="max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]"
              >
                <UilTimesCircle width={16} height={16} className="mr-1" /> Discard
              </NavLink>

              <Button
                onClick={handleSubmitNext}
                disabled={isSaving}
                className={`${
                  isSaving && 'text-[#9CA3AF] bg-[#F3F4F6]'
                } max-w-[150px] bg-white border-1 text-[#A7800C] text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
              >
                <UilArrowToRight width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Next'}
              </Button>

              <Button
                onClick={handleSubmit}
                disabled={isSaving}
                className={`min-w-[100px] ${
                  isSaving ? 'text-[#9CA3AF] bg-[#F3F4F6]' : 'bg-primary border-primary text-black'
                } max-w-[160px] border-none text-sm font-medium font-Rubik leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[44px]`}
              >
                <UilCheckCircle width={16} height={16} className="mr-1" />
                {isSaving ? 'Loading...' : 'Save & Exit'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditMaxPayout;
