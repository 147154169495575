import api from '../api';

export const getAchievementCategories = async () => {
  try {
    const response = await api.get('/admin/achievement-category');

    return response.data.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerGetAchievementCategories = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
