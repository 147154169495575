const splitArrayInSmallArrays = (array, size) => {
  const smallArrays = [];
  for (let i = 0; i < array.length; i += size) {
    const small = array.slice(i, i + size);
    smallArrays.push(small);
  }
  return smallArrays;
};

export { splitArrayInSmallArrays };
