import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { signIn, isErrorHandler } from '../../services/requests/signin';
import { recoveryPassword, isErrorHandlerPassword } from '../../services/requests/recoveryPassword';
import { confirmPassword } from '../../services/requests/confirmPassword';
import { getSchool } from '../../services/requests/getSchool';
import { getCompany } from '../../services/requests/getCompany';

const {
  forgotPasswordErr,
  forgotPassword,
  forgotPasswordBegin,
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await signIn(values);

      if (isErrorHandler(response)) {
        dispatch(loginErr(response.message));

        return;
      }

      const decodedAuthToken = jwt(response.data);

      if (!decodedAuthToken['cognito:groups']) {
        dispatch(loginErr("Student doesn't have access to the admin dashboard."));

        return;
      }

      Cookies.set('access_token', response.data);
      Cookies.set('logedIn', true);

      const responseSchool = await getSchool({ limit: 1000 });
      const schoolItems = [...responseSchool.schools];

      if (responseSchool.totalPages > 1) {
        const requestLength = Array.from(Array(responseSchool.totalPages), (_, index) => index + 1);
        const promisesSchool = requestLength.map((page) => getSchool({ limit: 1000, page: page + 1 }));
        const responsePromises = await Promise.all(promisesSchool);

        responsePromises.forEach((school) => schoolItems.push(...school.schools));
      }

      const responseCompany = await getCompany({ limit: 1000 });
      const companyItems = [...responseCompany.companies];

      if (responseCompany.totalPages > 1) {
        const requestCompanyLength = Array.from(Array(responseCompany.totalPages - 1), (_, index) => index + 1);
        const promisesCompany = requestCompanyLength.map((page) => getCompany({ limit: 1000, page: page + 1 }));
        const responsePromisesCompany = await Promise.all(promisesCompany);

        responsePromisesCompany.forEach((company) => companyItems.push(...company.companies));
      }

      localStorage.setItem('@AugmentUsAdmin-Schools', JSON.stringify(schoolItems));
      localStorage.setItem('@AugmentUsAdmin-Companies', JSON.stringify(companyItems));

      dispatch(loginSuccess(true));
      dispatch(loginErr(null));
      callback();
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const forgotPass = (values, callback) => {
  return async (dispatch) => {
    dispatch(forgotPasswordBegin());
    try {
      const response = await recoveryPassword({
        email: values,
      });

      if (isErrorHandlerPassword(response)) {
        dispatch(forgotPasswordErr(response.message));
      } else {
        dispatch(forgotPassword(values));
        dispatch(forgotPasswordErr(null));
        callback();
      }
    } catch (err) {
      dispatch(forgotPasswordErr(err));
    }
  };
};

const confirmPass = (values, callback) => {
  return async (dispatch) => {
    dispatch(forgotPasswordBegin());
    try {
      const response = await confirmPassword({
        ...values,
      });

      if (isErrorHandlerPassword(response)) {
        dispatch(forgotPasswordErr(response.message));
      } else {
        dispatch(forgotPassword(null));
        dispatch(forgotPasswordErr(null));
        callback();
      }
    } catch (err) {
      dispatch(forgotPasswordErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { forgotPass, confirmPass, login, logOut, register };
