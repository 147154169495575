import api from '../api';

export const getAchievements = async (currentPage, limit = 12) => {
  try {
    const response = await api.get('/admin/achievement', {
      params: {
        page: currentPage,
        limit,
      },
    });

    return {
      currentPage: response.data.currentPage,
      achievements: response.data.data,
      totalItems: response.data.totalItems,
      totalPages: response.data.totalPages,
    };
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerGetAchievement = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
